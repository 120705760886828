import React, { useEffect, useState } from "react";
import "./customStyles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faUsers } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import { shiftSchedule } from "../store/Dashboard/dashboardSlice";
import moment from "moment";

const getCurrentWeekDays = () => {
  const today = new Date();
  const startOfWeek = today.getDate() - today.getDay() + 1;
  const weekDays = Array.from({ length: 7 }, (_, i) => {
    const date = new Date(today.setDate(startOfWeek + i));
    return {
      day: date.toLocaleString("default", { weekday: "short" }),
      date: date.getDate(),
    };
  });
  return weekDays;
};



const ShiftSchedule = () => {
  const currentWeek = getCurrentWeekDays();
  const dispatch = useDispatch();
  const shift = useSelector((state) => state.dashboard.shift);

  useEffect(() => {
    dispatch(shiftSchedule());
  }, []);

  useEffect(() => {
    console.log(shift)
  }, [shift]);
  return (
    <div className="shift-schedule px-2">
      <div class="row">
        <div className="col-md-12 p-3 border-bottom">
          <div className="row ">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-4">
                  <div className="switchOuterDiv">
                    <div className="btn1 off">
                      <span><span>🕒</span> Shift View</span>
                    </div>
                    <div className="btn1 on">
                      <span> <span><FontAwesomeIcon icon={faUsers} /></span>Staff View</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="switchOuterDiv">
                    <span className="singleFilter"><span><FontAwesomeIcon icon={faFilter} /></span> Status All</span>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="switchOuterDiv">
                    <span className="singleFilter"><span><FontAwesomeIcon icon={faFilter} /></span> Team All</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-5"></div>
                <div className="col-md-3">
                  <div className="switchOuterDiv">
                    <div className="btn1 off justify-content-center">
                      <span>Week</span>
                    </div>
                    <div className="btn1 on justify-content-center">
                      <span>Month</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="switchOuterDiv">
                    <span className="singleFilter"> Current Week</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 border-bottom">
          <div className="row px-1">
            <div className="col emptyCol"></div>
            {currentWeek.map((day, index) => (
              <div className="col" key={index}>
                <span className="text-uppercase" style={{ fontSize: '10px' }}>{day.day}</span>
                <div>
                  <span>
                    <strong className="h5">{day.date}</strong>
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        {shift.length > 0 ? shift?.map((empshift) => (
          <div key={empshift._id} className="col-md-12 border-bottom">
            <div className="row px-1">
              <div className="col p-1">
                <div className="userInfo card d-flex">
                  <div className="mt-1"><span className="FirstLettersIcon">AN</span><strong>{empshift.name}</strong></div>
                  <div>Total : <span>{
                    // Sum hours from all shifts for this employee
                    empshift.shifts.reduce((total, shiftDetail) => {
                      const start = moment(shiftDetail?.start);
                      const end = moment(shiftDetail?.end);
                      const duration = moment.duration(end.diff(start));
                      const hours = duration.asHours();
                      return total + hours;
                    }, 0).toFixed(2) * 7 // Format to 2 decimal places
                  } hours</span></div>
                </div>
              </div>

              {currentWeek.map((day, index) => (
                <div key={index} className="col p-1 shift-col">
                  {empshift.shifts.map((shiftDetail, shiftIndex) => {
                    const start = moment(shiftDetail?.start);
                    const end = moment(shiftDetail?.end);
                    const duration = moment.duration(end.diff(start));
                    const hours = duration.asHours().toFixed(2); // Calculate and format hours to 2 decimal places

                    return (
                      <div key={shiftIndex} className="card">
                        <p className="mb-0 timing">
                          {start.format('hh:mm A')} - {end.format('hh:mm A')}
                        </p>
                        <p className="mb-0">
                          <span>🕒 {hours} Hours</span>
                        </p>
                      </div>
                    );
                  })}
                </div>
              ))}



            </div>
          </div>
        )) : ('')}
      </div>

    </div>
  );
};

export default ShiftSchedule;
